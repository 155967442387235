import React from 'react'

const DiversityText = props => {
  return (
    <section className="diversity-report__section d-section">
      <div
        className="diversity-report__text diversity-report-thin-width-column"
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      />
    </section>
  )
}

export default DiversityText
