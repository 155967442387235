import React, { Component } from 'react'

export default class DiversitySection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCurrent: true,
      uid: Math.ceil(Math.random() * 1000000),
    }
  }
  render() {
    return (
      <React.Fragment>
        <section
          className="diversity-scorecard d-section"
          id={this.props.currentData.primary.section_name
            .toLowerCase()
            .replace(/ /g, '-')}
        >
          <h2 className="diversity-scorecard__heading">
            <span className="diversity-report-thin-width-column">
              {this.props.currentData.primary.section_name}
            </span>
          </h2>
          <div
            className="diversity-scorecard__body diversity-report-thin-width-column"
            dangerouslySetInnerHTML={{
              __html: this.props.currentData.primary.data_body.html,
            }}
          />

          <div className="diversity-scorecard__data diversity-report-thin-width-column">
            {this.props.dataChunks.map((chunk, index) => (
              <table key={index}>
                <thead>
                  <tr>
                    <th>Score</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {chunk.map((dataPoint, index) => (
                    <tr key={index}>
                      <td>
                        {index === 0 && <span>Score</span>}
                        {dataPoint.data_point_percentage.toFixed(2)}
                        {index === 0 && (
                          <React.Fragment>
                            <span className="slash"> /</span>
                            <span className="ten">&thinsp;10</span>
                          </React.Fragment>
                        )}
                      </td>
                      <td>{dataPoint.data_point_description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        </section>
      </React.Fragment>
    )
  }
}
